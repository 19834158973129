import { FETCH_CATEGORIES_SUCCESS } from '../actions/types'

const initialState = {
  categories: []
}

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.articleCategories
      }
    default:
      return state
  }
}

export default categoriesReducer;
