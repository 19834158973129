import React from 'react'
import './css/style.css'

const Button = props => {
  const handleClick = () => {
    if (props.onClick) props.onClick()
  }
  return (
    <button
      id='button'
      className={`${props.className} ${props.loading ? 'loading' : ''} ${
        props.disabled ? 'disabled' : ''
      }`}
      style={{
        backgroundColor: !props.outlined
          ? props.color || 'var(--primary-color)'
          : 'inherit',
        color: props.outlined
          ? props.color || 'var(--primary-color)'
          : props.contentColor || 'white',
        boxShadow: props.outlined
          ? `inset 0 0 0 1px ${props.color || 'var(--primary-color)'}`
          : !props.depressed
          ? '0 2px 5px rgba(0, 0, 0, 0.2)'
          : 'none',
        borderRadius: props.rounded ? '999px' : '10px',
        ...props.style
      }}
      onClick={handleClick}
      disabled={props.disabled}
    >
      <div
        className='loader'
        style={{
          borderBottom: `2px solid ${props.color || 'var(--primary-color)'}`
        }}
      ></div>
      <div className='children d-flex align-items-center'>{props.children}</div>
    </button>
  )
}

export default Button
