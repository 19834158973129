import React from 'react'
import { ReactComponent as LeftArrow } from '../../../assets/icons/left-arrow 17.svg'
import { ReactComponent as Ellipse } from '../../../assets/icons/Ellipse 74.svg'
import { ReactComponent as CircledPlay } from '../../../assets/icons/CircledPlay.svg'
import { Link } from 'react-router-dom'
import {
  convertDate,
  deleteTags,
  getReadTime,
  isVideo,
  slugify
} from '../../../assets/js/globalFunctions'
import './css/style.css'

const ArticleCard = props => {
  const Articles = props => {
    const { article } = props
    return (
      <div id='article-card' className='mb-3'>
        <Link to={`/articles/${slugify(article.title)}?id=${article._id}`}>
          <div className='img-container'>
            {!isVideo(article.mediaUrl) && (
              <img src={article.mediaUrl} alt='article-picture' />
            )}
            {isVideo(article.mediaUrl) && (
              <div>
                <video>
                  <source src={article.mediaUrl + '#t=0.1'}></source>
                </video>
                <div
                  style={{
                    position: 'absolute',
                    zIndex: '2',
                    top: 0,
                    width: '100%',
                    height: '100%'
                  }}
                  className='d-flex align-items-center justify-content-center'
                >
                  <CircledPlay
                    style={{
                      maxWidth: '3rem',
                      minWidth: '3rem',
                      fill: 'rgba(38, 170, 118, .7)',
                      borderRadius: '50%',
                      padding: 0
                    }}
                    className='general-shadow'
                  />
                </div>
              </div>
            )}
          </div>
        </Link>
        {props.showArticleCategory ? (
          <p
            style={{
              color: '#26AA76',
              marginBottom: 0,
              textTransform: 'uppercase'
            }}
            className='mt-3'
          >
            {props.article.categoryId.name}
          </p>
        ) : (
          ''
        )}
        <div
          style={{
            overflow: 'hidden',
            width: '100%',
            height: '2.8rem',
            lineHeight: '1.4rem'
          }}
          className='mb-3 mt-3 font4 relative two-line-truncate'
        >
          <Link
            style={{color: 'var(--primary-text)'}}
            to={`/articles/${slugify(article.title)}?id=${article._id}`}
          >
            {props.article.title}
          </Link>
        </div>
        <div
          className='d-flex justify-content-between flex-wrap mt-n1 mb-3'
          style={{
            color: '#414141',
            fontSize: 'smaller'
          }}
        >
          <div className='mr-2 mt-2' style={{ textTransform: 'uppercase' }}>
            {convertDate(props.article.createdAt)}
          </div>
          <div className='d-flex align-items-center mt-2'>
            <Ellipse />
            <span style={{ marginLeft: '10px' }} className='text-uppercase'>
              {`${getReadTime(deleteTags(props.article.content))}`}
            </span>
          </div>
        </div>
        <Link
          id='buttonGreen'
          to={`/articles/${slugify(article.title)}?id=${article._id}`}
        >
          Read More
          <span id='read-more-arrow'>
            <LeftArrow />
          </span>
        </Link>
      </div>
    )
  }
  var rows = []
  for (var i = 0; i < props.items.length; i++) {
    rows.push(
      <Articles
        article={props.items[i]}
        showArticleCategory={props.showArticleCategory}
        key={i}
      />
    )
  }

  let rowsList = rows.map((row, index) => {
    return (
      <div className='col-sm-6 col-md-4 col-lg-3 mb-3' key={index}>
        {row}
      </div>
    )
  })

  return (
    <div className={props.className} style={props.style}>
      <div className='d-flex justify-content-between py-3'>
        {props.category && (
          <div className='category-name' style={{ textTransform: 'uppercase' }}>
            {props.category.name}
          </div>
        )}
        {props.displayShowAll && (
          <Link
            to={`/categories/${props.category._id}`}
            style={{ color: 'var(--primary-color)' }}
            className='hover-style'
          >
            Show All
          </Link>
        )}
      </div>
      <div className='row article-row'>{rowsList}</div>
    </div>
  )
}

export default ArticleCard
