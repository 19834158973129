export const INPUT_CHANGE = 'INPUT_CHANGE'

export const FETCH_USERS_COUNT_SUCCESS = 'FETCH_USERS_COUNT_SUCCESS'
export const FETCH_USERS_COUNT_FAILURE = 'FETCH_USERS_COUNT_FAILURE'

export const FETCH_CATEGORIES_ARTICLES_SUCCESS = 'FETCH_CATEGORIES_ARTICLES_SUCCESS'
export const FETCH_CATEGORIES_ARTICLES_FAILURE = 'FETCH_CATEGORIES_ARTICLES_FAILURE'

export const FETCH_CATEGORY_ARTICLES_SUCCESS = 'FETCH_CATEGORY_ARTICLES_SUCCESS'
export const FETCH_CATEGORY_ARTICLES_FAILURE = 'FETCH_CATEGORY_ARTICLES_FAILURE'

export const FETCH_CATEGORY_ARTICLES_COUNT_SUCCESS = 'FETCH_CATEGORY_ARTICLES_COUNT_SUCCESS'
export const FETCH_CATEGORY_ARTICLES_COUNT_FAILURE = 'FETCH_CATEGORY_ARTICLES_COUNT_FAILURE'

export const INCREMENT_ARTICLE_VIEWS_SUCCESS = 'INCREMENT_ARTICLE_VIEWS_SUCCESS'
export const INCREMENT_ARTICLE_VIEWS_FAILURE = 'INCREMENT_ARTICLE_VIEWS_FAILURE'

export const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS'
export const FETCH_ARTICLE_FAILURE = 'FETCH_ARTICLE_FAILURE'

export const FETCH_HEADLINE_ARTICLE_SUCCESS = 'FETCH_HEADLINE_ARTICLE_SUCCESS'
export const FETCH_HEADLINE_ARTICLE_FAILURE = 'FETCH_HEADLINE_ARTICLE_FAILURE'

export const FETCH_SEARCH_ARTICLES_SUCCESS = 'FETCH_SEARCH_ARTICLES_SUCCESS'
export const FETCH_SEARCH_ARTICLES_FAILURE = 'FETCH_SEARCH_ARTICLES_FAILURE'

export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS'
export const FETCH_ARTICLES_FAILURE = 'FETCH_ARTICLES_FAILURE'

export const ADD_SUBSCRIBER_SUCCESS = 'ADD_SUBSCRIBER_SUCCESS'
export const ADD_SUBSCRIBER_FAILURE = 'ADD_SUBSCRIBER_FAILURE'
export const DELETE_SUBSCRIBER_SUCCESS = 'DELETE_SUBSCRIBER_SUCCESS'
export const DELETE_SUBSCRIBER_FAILURE = 'DELETE_SUBSCRIBER_FAILURE'

export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS'
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const GET_ERRORS = 'GET_ERRORS'

export const GET_SUCCESS = 'GET_SUCCESS'
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS'