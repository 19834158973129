import API from '../../assets/js/api'
import { returnErrors } from './errorActions'
import { returnSuccess } from './successActions'

import { FETCH_USERS_COUNT_SUCCESS, FETCH_USERS_COUNT_FAILURE } from './types'

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  )
}
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id))
}

export const fetchUsersCount = () => async dispatch => {
  try {
    const result = await API.getUsersCount()

    dispatch({ type: FETCH_USERS_COUNT_SUCCESS, payload: result.data.data })
    dispatchSuccess(dispatch, ``, FETCH_USERS_COUNT_SUCCESS)
  } catch (err) {
    dispatchError(dispatch, err, FETCH_USERS_COUNT_FAILURE)
  }
}
