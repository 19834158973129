import API from '../../assets/js/api'
import { returnErrors } from './errorActions'
import { returnSuccess } from './successActions'

import {
  FETCH_CATEGORIES_ARTICLES_SUCCESS,
  FETCH_CATEGORIES_ARTICLES_FAILURE,
  INCREMENT_ARTICLE_VIEWS_SUCCESS,
  INCREMENT_ARTICLE_VIEWS_FAILURE,
  FETCH_CATEGORY_ARTICLES_SUCCESS,
  FETCH_CATEGORY_ARTICLES_FAILURE,
  FETCH_CATEGORY_ARTICLES_COUNT_SUCCESS,
  FETCH_CATEGORY_ARTICLES_COUNT_FAILURE,
  FETCH_ARTICLE_SUCCESS,
  FETCH_ARTICLE_FAILURE,
  FETCH_ARTICLES_SUCCESS,
  FETCH_ARTICLES_FAILURE,
  FETCH_HEADLINE_ARTICLE_SUCCESS,
  FETCH_HEADLINE_ARTICLE_FAILURE,
  FETCH_SEARCH_ARTICLES_SUCCESS,
  FETCH_SEARCH_ARTICLES_FAILURE
} from './types'

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  )
}
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id))
}

export const fetchArticle = id => async dispatch => {
  try {
    document.body.classList.add('loading-indicator')
    const result = await API.getArticle(id)

    dispatch({
      type: FETCH_ARTICLE_SUCCESS,
      payload: result.data.data
    })
    dispatchSuccess(dispatch, ``, FETCH_ARTICLE_SUCCESS)
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, FETCH_ARTICLE_FAILURE)
  }
}

export const fetchArticles = query => async dispatch => {
  try {
    document.body.classList.add('loading-indicator')
    const result = await API.getArticles(query)

    dispatch({
      type: FETCH_ARTICLES_SUCCESS,
      payload: result.data.data
    })
    dispatchSuccess(dispatch, ``, FETCH_ARTICLES_SUCCESS)
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, FETCH_ARTICLES_FAILURE)
  }
}

export const fetchHeadlineArticle = () => async dispatch => {
  try {
    document.body.classList.add('loading-indicator')
    const result = await API.getArticles({ isHeadline: true, limit: 1 })
    dispatch({
      type: FETCH_HEADLINE_ARTICLE_SUCCESS,
      payload: result.data.data
    })
    dispatchSuccess(dispatch, ``, FETCH_HEADLINE_ARTICLE_SUCCESS)
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, FETCH_HEADLINE_ARTICLE_FAILURE)
  }
}
export const fetchSearchArticles = keywords => async dispatch => {
  try {
    const result = await API.searchArticles(keywords)
    dispatch({
      type: FETCH_SEARCH_ARTICLES_SUCCESS,
      payload: result.data.data
    })
    dispatchSuccess(dispatch, ``, FETCH_SEARCH_ARTICLES_SUCCESS)
  } catch (err) {
    dispatchError(dispatch, err, FETCH_SEARCH_ARTICLES_FAILURE)
  }
}

export const fetchCategoriesArticles = data => async dispatch => {
  try {
    document.body.classList.add('loading-indicator')
    const result = await API.getCategoriesArticles(data)

    dispatch({
      type: FETCH_CATEGORIES_ARTICLES_SUCCESS,
      payload: result.data.data
    })
    dispatchSuccess(dispatch, ``, FETCH_CATEGORIES_ARTICLES_SUCCESS)
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, FETCH_CATEGORIES_ARTICLES_FAILURE)
  }
}

export const fetchCategoryArticles = (id, query) => async dispatch => {
  try {
    document.body.classList.add('loading-indicator')
    const result = await API.getCategoryArticles(id, query)

    dispatch({
      type: FETCH_CATEGORY_ARTICLES_SUCCESS,
      payload: result.data.data
    })
    dispatchSuccess(dispatch, ``, FETCH_CATEGORY_ARTICLES_SUCCESS)
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    dispatchError(dispatch, err, FETCH_CATEGORY_ARTICLES_FAILURE)
    document.body.classList.remove('loading-indicator')
  }
}
export const fetchCategoryArticlesCount = (id) => async dispatch => {
  try {
    // document.body.classList.add('loading-indicator')
    const result = await API.getCategoryArticlesCount(id)

    dispatch({
      type: FETCH_CATEGORY_ARTICLES_COUNT_SUCCESS,
      payload: result.data.data
    })
    dispatchSuccess(dispatch, ``, FETCH_CATEGORY_ARTICLES_COUNT_SUCCESS)
    // document.body.classList.remove('loading-indicator')
  } catch (err) {
    dispatchError(dispatch, err, FETCH_CATEGORY_ARTICLES_COUNT_FAILURE)
    // document.body.classList.remove('loading-indicator')
  }
}

export const incrementArticleViews = id => async dispatch => {
  try {
    await API.incrementArticleViews(id)
    dispatchSuccess(dispatch, ``, INCREMENT_ARTICLE_VIEWS_SUCCESS)
  } catch (err) {
    dispatchError(dispatch, err, INCREMENT_ARTICLE_VIEWS_FAILURE)
  }
}
