export function convertDate (inputFormat) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  function pad (s) {
    return s < 10 ? '0' + s : s
  }
  var d = new Date(inputFormat)
  let day = d.getDate()
  let month = d.getMonth()
  let year = d.getFullYear()
  if (isNaN(day) || isNaN(month) || isNaN(year)) return 'Invalid date'
  return `${months[month]} ${pad(day)}, ${year}`
}

export const deleteTags = (str, spaced) => {
  if (str.includes('<') && str.includes('>')) {
    let openTag = str.indexOf('<')
    let closeTag = str.indexOf('>')
    let tagElem = str.substring(openTag, closeTag + 1)
    str = str.replace(tagElem, spaced ? ' ' : '')
    return deleteTags(str)
  } else return str
}

export const getReadTime = str => {
  const words = str
    .trim()
    .split(' ')
    .filter(str_ => str_.trim())

  const time = Math.ceil(words.length / 225)

  return `${time} min${time > 1 ? 's' : ''} read`
}
export const isVideo = url => {
  const ext = url.slice(url.lastIndexOf('.') + 1)
  return ext === 'mp4' || ext === '3gp' || ext === 'avi'
}
export const slugify = url => {
  return url.toLowerCase().replace(/ /g, '-')
}
