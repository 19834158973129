import {
  FETCH_CATEGORIES_ARTICLES_SUCCESS,
  FETCH_CATEGORY_ARTICLES_SUCCESS,
  FETCH_CATEGORY_ARTICLES_COUNT_SUCCESS,
  FETCH_ARTICLE_SUCCESS,
  FETCH_HEADLINE_ARTICLE_SUCCESS,
  FETCH_ARTICLES_SUCCESS,
  FETCH_SEARCH_ARTICLES_SUCCESS
} from '../actions/types'

const initialState = {
  categoriesArticles: [],
  categoryArticles: [],
  article: {},
  articlesCount: 0,
  articles: [],
  headlineArticle: {},
  searchArticles: []
}

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.payload.article
      }
    case FETCH_HEADLINE_ARTICLE_SUCCESS:
      return {
        ...state,
        headlineArticle: action.payload.articles[0]
      }
    case FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload.articles
      }
    case FETCH_SEARCH_ARTICLES_SUCCESS:
      return {
        ...state,
        searchArticles: action.payload.articles
      }
    case FETCH_CATEGORIES_ARTICLES_SUCCESS:
      return {
        ...state,
        categoriesArticles: action.payload.categoriesArticles
      }
    case FETCH_CATEGORY_ARTICLES_SUCCESS:
      return {
        ...state,
        categoryArticles: action.payload.categoryArticles
      }
    case FETCH_CATEGORY_ARTICLES_COUNT_SUCCESS:
      return {
        ...state,
        articlesCount: action.payload.articlesCount
      }
    default:
      return state
  }
}

export default categoriesReducer
