import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../../assets/images/LogoGreen.png'
import { ReactComponent as Mail } from '../../../assets/icons/Mail.svg'
import { ReactComponent as Phone } from '../../../assets/icons/Phone.svg'
import { ReactComponent as Location } from '../../../assets/icons/Location.svg'
import { fetchUsersCount } from '../../../redux/actions/footerActions'
import { inputChange, addSubscriber } from '../../../redux/actions/subscribersActions'
import { clearErrors } from "../../../redux/actions/errorActions";
import { clearSuccess } from "../../../redux/actions/successActions";
import { connect } from 'react-redux'
import Button from '../button/button.component'
import Alert from '../alert/alert.component'
import { Link } from 'react-router-dom'
import './css/style.css'

const Footer = props => {
  const { error, success, usersCount, subscriberEmail } = props

  const [alert, setAlert] = useState({
    show: false,
    message: "",
  });

  const mounted = useRef(false)

  useEffect(() => {
    if (!mounted.current) {
      props.fetchUsersCount()
      mounted.current = true
    }
    else{
      if (error.id) {
        const message =
          typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
        let alert_ = { type: "error", show: true, message };
        if (error.id === "ADD_SUBSCRIBER_FAILURE") {
          setAlert({ ...alert_});
          props.clearErrors();
        }
        props.clearErrors();
      } else if (success.id) {
        let alert_ = { type: "success", show: true, message: success.msg };
        if (success.id === "ADD_SUBSCRIBER_SUCCESS") {
          setAlert({ ...alert_ });
          props.clearSuccess();
        }
        props.clearSuccess();
      }
    }
  })

  const handleInput = (e) => {
    props.inputChange(e.target.name, e.target.value);
  };

  const handleSubscription = async () => {
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
    let message;
    if(!subscriberEmail) message = 'Email is required';
    if(!subscriberEmail.match(emailRegex)) message = "Email is invalid";  
    if(message) setAlert({ type: "error", show: true, message });
    if(!message) await props.addSubscriber({ email: subscriberEmail });
  }

  return (
    <div id='footer'>
      <div
        className='page-padding d-flex flex-column align-items-center'
        style={{ backgroundColor: '#F5F5F5' }}
      >
        <h3 className='center mt-n2 pt-0 font4'>
          Want updates directly in your mailbox?
        </h3>
        <p style={{ color: '#333333' }} className='mt-3 center'>
          Enter your email to get latest news from Afrilearn.
        </p>
        <div className="d-flex justify-content-center">
            {alert.show && (
              <Alert
                type={alert.type}
                message={alert.message}
                style={{ maxWidth: 500, flexGrow: 1 }}
                className="mt-3"
                onClose={() => {
                  setAlert({
                    show: false,
                    message: "",
                  });
                }}
              />
            )}
          </div>
        <div className='d-flex mt-3' style={{ width: '100%', maxWidth: 470 }}>
          <input
            type='text'
            placeholder='Enter your email here'
            style={{
              backgroundColor: 'white',
              padding: '13px 30px',
              border: 'none',
              flexGrow: 1
            }}
            onChange={handleInput}
            value={subscriberEmail}
            name='subscriberEmail'
          />
          <button
            style={{
              border: 'none',
              backgroundColor: 'var(--primary-color)',
              color: 'white',
              fontSize: '.85rem',
              padding: '.2rem 2.2rem',
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px'
            }}
            onClick={handleSubscription}
          >
            Subscribe
          </button>
        </div>
      </div>
      <div className='w-100 page-padding'>
        <div className='d-flex flex-column align-items-center'>
          <div style={{ maxWidth: 500 }} className='center'>
            Get ahead with Afrilearn! We provide every primary and secondary
            student freedom to learn curriculum relevant subjects and topics
            anytime, anywhere.
          </div>
          <a href='https://myafrilearn.com/register' target='blank_'>
            <Button className='mt-5'>Join for free</Button>
          </a>
        </div>
      </div>
      <div className='info page-padding'>
        <div>
          <img src={Logo} alt='Logo' />
          <p className='mt-3'>
            Afrilearn actively leverages a network of high quality teachers,
            animators, and developers to provide affordable world-class
            education for Africans anywhere
          </p>
        </div>
        <div className='d-flex justify-content-md-center'>
          <div>
            <p>About</p>
            <div>
              <Link to='/media' className='hover-style block'>
                Media
              </Link>
              <a
                href='https://myafrilearn.com/contact#career'
                target='blank_'
                className='hover-style block mt-2'
              >
                Careers
              </a>
              <a
                href='https://myafrilearn.com/about#team'
                target='blank_'
                className='hover-style block mt-2'
              >
                Our Team
              </a>
              <a
                href='https://myafrilearn.com/partnership'
                target='blank_'
                className='hover-style block mt-2'
              >
                Partnership
              </a>
              <a
                href='https://myafrilearn.com/contact'
                target='blank_'
                className='hover-style block mt-2'
              >
                Contact
              </a>
            </div>
          </div>
        </div>
        <div>
          <p>Contact</p>
          <div>
            <div className='d-flex'>
              <Mail style={{ minWidth: '1.3rem', maxWidth: '1.3rem' }} />
              <div className='ml-3'>hello@myafrilearn.com</div>
            </div>
            <div className='d-flex mt-3'>
              <Phone style={{ minWidth: '1.3rem', maxWidth: '1.3rem' }} />
              <div className='ml-3'>
                <div>+234 802 785 5262</div>
                <div>+234 805 154 4949</div>
              </div>
            </div>
            <div className='d-flex mt-3'>
              <Location style={{ minWidth: '1.3rem', maxWidth: '1.3rem' }} />
              <div className='ml-3'>
                6 Gbemisola Street,
                <br /> Allen Avenue, Ikeja,
                <br /> Lagos, Nigeria
              </div>
            </div>
          </div>
        </div>
        <div>
          <p>Key Stats</p>
          <div>
            <div>
              <div>Registered Students</div>
              <div>{usersCount.students}</div>
            </div>
            <div className='mt-4'>
              <div>Registered Teachers</div>
              <div>{usersCount.teachers}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  usersCount: state.footer.usersCount,
  subscriberEmail: state.subscribers.subscriberEmail,
  error: state.error,
  success: state.success
})
export default connect(mapStateToProps, {
  fetchUsersCount,
  addSubscriber,
  inputChange,
  clearErrors,
  clearSuccess
})(Footer)

