import React, { useEffect, useRef } from 'react'
import {
  fetchCategoriesArticles,
  fetchArticles,
  fetchHeadlineArticle
} from '../../../redux/actions/articlesActions'
import { clearSuccess } from '../../../redux/actions/successActions'
import { connect } from 'react-redux'
import { ReactComponent as Ellipse } from '../../../assets/icons/Ellipse 74.svg'
import ArticleCard from '../../../components/includes/article-card/article-card.component'
import {
  convertDate,
  deleteTags,
  getReadTime,
  isVideo,
  slugify
} from '../../../assets/js/globalFunctions'
import { Link } from 'react-router-dom'
import './css/style.css'

const Homepage = props => {
  const {
    categoriesArticles,
    popularArticles,
    headlineArticle,
    categories,
    success
  } = props

  const mounted = useRef(false)

  useEffect(() => {
    if (!mounted.current) {
      props.fetchArticles({ limit: 4, sort: '-views' })
      props.fetchHeadlineArticle()
      mounted.current = true
    } else {
      if (success.id) {
        switch (success.id) {
          case 'FETCH_CATEGORIES_SUCCESS':
            break
        }
        props.clearSuccess()
      }
    }
  })

  useEffect(() => {
    if (categories.length) {
      props.fetchCategoriesArticles({
        categories: categories.map(cat => ({ name: cat.name, _id: cat._id })),
        limit: 4
      })
    }
  }, [categories])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id='home-page' className='body-padding'>
      {headlineArticle._id && (
        <div>
          <div className='d-flex flex-column align-items-center'>
            <Link to={`categories/${slugify(headlineArticle.categoryId.name)}?id=${headlineArticle.categoryId._id}`}>
              <p style={{ color: '#FF5B5B' }}>
                {headlineArticle.categoryId.name}
              </p>
            </Link>
            <Link
              to={`/articles/${slugify(headlineArticle.title)}?id=${headlineArticle._id}`}
              style={{ color: 'var(--primary-text-color)' }}
            >
              <h3
                className='mb-3'
                style={{ maxWidth: 600, textAlign: 'center' }}
              >
                {headlineArticle.title}
              </h3>
            </Link>
            <div
              className='d-flex justify-content-between'
              style={{
                maxWidth: 300,
                width: '80%',
                color: '#414141',
                fontSize: 'smaller'
              }}
            >
              <p style={{ textTransform: 'uppercase' }}>
                {convertDate(headlineArticle.createdAt)}
              </p>
              <p className='d-flex align-items-center'>
                <Ellipse className='mr-1' />
                {`${getReadTime(deleteTags(headlineArticle.content))}`}
              </p>
            </div>
          </div>
          <div className='mb-3 mt-2 d-flex justify-content-center'>
            <div className='img-backdrop'>
              {!isVideo(headlineArticle.mediaUrl) && (
                <div className='img-container'>
                  <Link to={`/articles/${slugify(headlineArticle.title)}?id=${headlineArticle._id}`}>
                    <img src={headlineArticle.mediaUrl} />
                  </Link>
                </div>
              )}
              {isVideo(headlineArticle.mediaUrl) && (
                <video controls>
                  <source src={headlineArticle.mediaUrl} />
                </video>
              )}
            </div>
          </div>
        </div>
      )}
      <div className='body-padding'>
        <ArticleCard
          items={popularArticles}
          category={{ name: 'Most Popular' }}
          showArticleCategory
        />
        {categoriesArticles.map(cat => (
          <ArticleCard
            items={cat.articles}
            category={cat}
            displayShowAll
            key={cat._id}
          />
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  categoriesArticles: state.articles.categoriesArticles,
  categories: state.categories.categories,
  popularArticles: state.articles.articles,
  headlineArticle: state.articles.headlineArticle,
  error: state.error,
  success: state.success
})
export default connect(mapStateToProps, {
  fetchCategoriesArticles,
  clearSuccess,
  fetchArticles,
  fetchHeadlineArticle
})(Homepage)
