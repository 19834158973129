import React, { useEffect, useRef } from 'react'
import { ReactComponent as Facebook } from '../../../assets/icons/facebook 1.svg'
import { ReactComponent as Twitter } from '../../../assets/icons/twitter 1.svg'
import { ReactComponent as Whatsapp } from '../../../assets/icons/Whatsapp.svg'
import { ReactComponent as LinkedIn } from '../../../assets/icons/linkedin 1.svg'
import { ReactComponent as Ellipse } from '../../../assets/icons/Ellipse 74.svg'
import ArticleCard from '../../../components/includes/article-card/article-card.component'
import {
  fetchArticle,
  fetchCategoryArticles,
  incrementArticleViews
} from '../../../redux/actions/articlesActions'
import { connect } from 'react-redux'
import { useParams, Link, useLocation } from 'react-router-dom'
import {
  convertDate,
  deleteTags,
  getReadTime,
  isVideo,
  slugify
} from '../../../assets/js/globalFunctions'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton
} from 'react-share'
import './css/style.css'

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

const Article = props => {
  const { article, relatedArticles, success, error } = props

  const location = useLocation()
  const query = useQuery()

  const id = query.get('id')

  const mounted = useRef(false)

  useEffect(() => {
    if (mounted.current && article._id) {
      props.fetchCategoryArticles(article.categoryId._id, {
        limit: 4,
        skip: 0,
        query: { _id: { $ne: article._id } }
      })
      props.incrementArticleViews(id)
    }
  }, [article])

  useEffect(() => {
    if (mounted.current) {
      props.fetchArticle(id)
    }
  }, [location.pathname])

  useEffect(() => {
    if (!mounted.current) {
      props.fetchArticle(id)
      // props.incrementViews(id)
      mounted.current = true
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id='article'>
      {article._id && (
        <>
          <div className='body-padding'>
            <div className='d-flex flex-column align-items-center'>
              <Link
                to={`/categories/${slugify(article.categoryId.name)}?id=${
                  article.categoryId._id
                }`}
              >
                <p style={{ color: '#FF5B5B' }}>{article.categoryId.name}</p>
              </Link>
              <h3
                className='mb-3'
                style={{ maxWidth: 600, textAlign: 'center' }}
              >
                {article.title}
              </h3>
              <div
                className='d-flex justify-content-between'
                style={{
                  maxWidth: 300,
                  width: '80%',
                  color: '#414141',
                  fontSize: 'smaller'
                }}
              >
                <p style={{ textTransform: 'uppercase' }}>
                  {convertDate(article.createdAt)}
                </p>
                <p className='d-flex align-items-center'>
                  <Ellipse className='mr-1' />
                  {`${getReadTime(deleteTags(props.article.content))}`}
                </p>
              </div>
            </div>
            <div className='mb-3 mt-2 d-flex justify-content-center'>
              <div className='img-backdrop'>
                {!isVideo(article.mediaUrl) && (
                  <div className='img-container'>
                    <img src={article.mediaUrl} />
                  </div>
                )}
                {isVideo(article.mediaUrl) && (
                  <video controls>
                    <source src={article.mediaUrl} />
                  </video>
                )}
              </div>
            </div>
            <div className='article-body'>
              <div className='d-flex flex-column-reverse flex-md-row mt-3 align-items-start'>
                <div className='social-icons'>
                  <div className='d-flex flex-md-column mt-4 mt-md-2 mr-md-5 ml-md-5'>
                    <FacebookShareButton
                      quote={article.title}
                      url={window.location.href}
                    >
                      <Facebook
                        style={{
                          fill: 'var(--primary-color)',
                          minWidth: '1.5em',
                          maxWidth: '1.5em'
                        }}
                      />
                    </FacebookShareButton>
                    <TwitterShareButton
                      title={article.title}
                      hashtags={['Afrilearn', 'AfrilearnEducation']}
                      url={window.location.href}
                      className='my-0 mt-md-3 ml-3 ml-md-0'
                    >
                      <Twitter
                        style={{
                          fill: 'var(--primary-color)',
                          minWidth: '1.5em',
                          maxWidth: '1.5em'
                        }}
                      />
                    </TwitterShareButton>
                    <WhatsappShareButton
                      title={article.title}
                      url={window.location.href}
                      className='my-0 mt-md-3 ml-3 ml-md-0'
                    >
                      <Whatsapp
                        style={{
                          fill: 'var(--primary-color)',
                          minWidth: '1.5em',
                          maxWidth: '1.5em'
                        }}
                      />
                    </WhatsappShareButton>
                    <LinkedinShareButton
                      title={article.title}
                      url={window.location.href}
                      className='my-0 mt-md-3 ml-3 ml-md-0'
                    >
                      <LinkedIn
                        style={{
                          fill: 'var(--primary-color)',
                          minWidth: '1.5em',
                          maxWidth: '1.5em'
                        }}
                      />
                    </LinkedinShareButton>
                  </div>
                </div>
                <div style={{ flexGrow: 1 }} style={{ lineHeight: '30px' }}>
                  <p style={{ color: 'var(--secondary-text)' }}>
                    Written by {article.creatorId.firstName}{' '}
                    {article.creatorId.lastName}
                  </p>
                  <div
                    style={{ color: '#171717' }}
                    className='article-content'
                    dangerouslySetInnerHTML={{ __html: article.content }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          {relatedArticles.length > 0 && (
            <ArticleCard
              items={relatedArticles}
              category={{ name: 'Related Articles' }}
              className='body-padding pt-3'
              style={{ paddingTop: 0 }}
            />
          )}
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  article: state.articles.article,
  relatedArticles: state.articles.categoryArticles,
  success: state.success,
  error: state.error
})

export default connect(mapStateToProps, {
  fetchArticle,
  fetchCategoryArticles,
  incrementArticleViews
})(Article)
