import React, { useState, useEffect, useRef } from 'react'
import {
  fetchCategoryArticles,
  fetchCategoryArticlesCount
} from '../../../redux/actions/articlesActions'
import { fetchCategories } from '../../../redux/actions/categoriesActions'
import { connect } from 'react-redux'
import ArticleCard from '../../../components/includes/article-card/article-card.component'
import Pagination from '../../../components/includes/pagination/pagination.component'
import { useLocation } from 'react-router-dom'

const PaginationRow = Pagination(ArticleCard)
const limit = 12

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

const Category = props => {
  const { categoryArticles, articlesCount, categories } = props
  const [skip, setSkip] = useState(0)
  const query = useQuery()

  const categoryId = query.get('id')

  useEffect(() => {
    setSkip(0)
    props.fetchCategoryArticlesCount(categoryId)
  }, [categoryId])

  useEffect(() => {
    props.fetchCategoryArticles(categoryId, { skip, limit })
    props.fetchCategories()
  }, [skip, categoryId])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const getCategory = () => {
    return categories.find(cat => cat._id === categoryId)
  }

  return (
    <div className='body-padding'>
      <div>
        <PaginationRow
          count={articlesCount}
          skip={skip}
          itemsPerPage={limit}
          items={categoryArticles}
          category={getCategory()}
          onPageChange={pageNumber => {
            setSkip((pageNumber - 1) * limit)
          }}
          style={{ marginTop: '-15px' }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  categoryArticles: state.articles.categoryArticles,
  categories: state.categories.categories,
  articlesCount: state.articles.articlesCount
})
export default connect(mapStateToProps, {
  fetchCategoryArticles,
  fetchCategoryArticlesCount,
  fetchCategories
})(Category)
