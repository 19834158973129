import { combineReducers } from 'redux'
import footerReducer from './footerReducer'
import categoriesReducer from './categoriesReducer'
import articlesReducer from './articlesReducer'
import subscribersReducer from './subscribersReducer'
import successReducer from './successReducer'
import errorReducer from './errorReducer'

export default combineReducers({
  footer: footerReducer,
  categories: categoriesReducer,
  articles: articlesReducer,
  subscribers: subscribersReducer,
  success: successReducer,
  error: errorReducer
})
