import axios from 'axios'

const URL = 'https://afrilearn-cms.herokuapp.com/api/v1/'
const LocalURL = 'http://localhost:5000/api/v1/'

export default {
  url: URL,
  headers (fileupload = false) {
    const token = localStorage.getItem('token')

    let header = {}
    if (fileupload) {
      header['Content-type'] = 'multipart/form-data'
    } else {
      header['Content-type'] = 'application/json'
      header['Accept'] = '*/*'
      header['Access-Control-Allow-Origin'] = '*'
    }
    if (token && token !== undefined) {
      header['token'] = token
    }
    return header
  },

  getUsersCount () {
    return axios({
      method: 'get',
      url: `${this.url}counts/users`,
      headers: this.headers()
    })
  },

  getArticle (id) {
    return axios({
      method: 'get',
      url: `${this.url}blog/articles/${id}`,
      headers: this.headers()
    })
  },

  getArticles (params) {
    return axios({
      method: 'get',
      url: `${this.url}blog/articles`,
      headers: this.headers(),
      params
    })
  },
  searchArticles (keywords) {
    return axios({
      method: 'get',
      url: `${this.url}blog/articles/search/${keywords}`,
      headers: this.headers()
    })
  },

  getCategories () {
    return axios({
      method: 'get',
      url: `${this.url}blog/categories/`,
      headers: this.headers()
    })
  },

  getCategoriesArticles (params) {
    return axios({
      method: 'get',
      url: `${this.url}blog/categories/articles`,
      headers: this.headers(),
      params
    })
  },

  getCategoryArticles (id, params) {
    return axios({
      method: 'get',
      url: `${this.url}blog/categories/${id}/articles`,
      headers: this.headers(),
      params
    })
  },

  getCategoryArticlesCount (id) {
    return axios({
      method: 'get',
      url: `${this.url}blog/categories/${id}/articles-count`,
      headers: this.headers()
    })
  },

  incrementArticleViews (id) {
    return axios({
      method: 'patch',
      url: `${this.url}blog/articles/${id}/increment-views`,
      headers: this.headers()
    })
  },

  addSubscriber (data) {
    return axios({
      method: 'post',
      url: `${this.url}subscribers`,
      headers: this.headers(),
      data
    })
  },

  removeSubscriber (email) {
    return axios({
      method: 'delete',
      url: `${this.url}subscribers/${email}`,
      headers: this.headers()
    })
  }
}
