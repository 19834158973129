import { FETCH_USERS_COUNT_SUCCESS } from '../actions/types'

const initialState = {
  usersCount: {
    students: 0,
    teachers: 0
  }
}

const usersCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_COUNT_SUCCESS:
      return {
        ...state,
        usersCount: action.payload
      }
    default:
      return state
  }
}

export default usersCountReducer
