import API from '../../assets/js/api'
import { returnErrors } from './errorActions'
import { returnSuccess } from './successActions'

import {
    ADD_SUBSCRIBER_SUCCESS,
    ADD_SUBSCRIBER_FAILURE,
    DELETE_SUBSCRIBER_SUCCESS,
    DELETE_SUBSCRIBER_FAILURE,
    INPUT_CHANGE,
} from './types'

const dispatchError = (dispatch, err, id) => {
    dispatch(
        returnErrors(
            err.response.data.errors
                ? err.response.data.errors
                : err.response.data.error,
            err.response.data.status,
            id
        )
    )
}
const dispatchSuccess = (dispatch, message, id) => {
    dispatch(returnSuccess(message, id))
}

export const inputChange = (name, value) => (dispatch) => {
    dispatch({ type: INPUT_CHANGE, payload: { name, value } });
};

export const addSubscriber = (data) => async (dispatch, getState) => {
    try {
        document.body.classList.add("loading-indicator");
        const result = await API.addSubscriber(data);

        dispatch({
            type: ADD_SUBSCRIBER_SUCCESS,
            payload: { subscriber: result.data.data.subscriber },
        });
        dispatchSuccess(
            dispatch,
            `Subscription added successfully`,
            ADD_SUBSCRIBER_SUCCESS
        );
        document.body.classList.remove("loading-indicator");
    } catch (err) {
        document.body.classList.remove("loading-indicator");
        dispatchError(dispatch, err, ADD_SUBSCRIBER_FAILURE);
    }
};

export const removeSubscriber = (email) => async (dispatch) => {
    try {
        document.body.classList.add("loading-indicator");
        const result = await API.removeSubscriber(email);

        dispatch({ type: DELETE_SUBSCRIBER_SUCCESS, payload: email });
        dispatchSuccess(dispatch, result.data.data.message, DELETE_SUBSCRIBER_SUCCESS);
        document.body.classList.remove("loading-indicator");
    } catch (err) {
        document.body.classList.remove("loading-indicator");
        dispatchError(dispatch, err, DELETE_SUBSCRIBER_FAILURE);
    }
};
