import API from '../../assets/js/api'
import { returnErrors } from './errorActions'
import { returnSuccess } from './successActions'

import { FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAILURE } from './types'

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  )
}
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id))
}

export const fetchCategories = () => async dispatch => {
  try {
    const result = await API.getCategories()

    dispatch({ type: FETCH_CATEGORIES_SUCCESS, payload: result.data.data })
    dispatchSuccess(dispatch, ``, FETCH_CATEGORIES_SUCCESS)
  } catch (err) {
    dispatchError(dispatch, err, FETCH_CATEGORIES_FAILURE)
  }
}
