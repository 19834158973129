import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Homepage from '../../components/screens/Homepage/homepage.component'
import Article from '../../components/screens/article/article.component'
import Category from '../../components/screens/category/category.component'

import Toolbar from './toolbar/toolbar.component'
import Footer from './footer/footer.component'
import ScrollToTop from './scroll-to-top/scroll-to-top.component'

const MyNav = () => {
  return (
    <Router>
      <ScrollToTop>
        <div className='d-flex'>
          <div style={{ width: '100%' }}>
            <Toolbar />
            <Switch>
              <Route exact path='/' component={Homepage} />
              <Route exact path='/articles/:title' component={Article} />
              <Route exact path='/categories/:name' component={Category} />
            </Switch>
            <Footer />
          </div>
        </div>
      </ScrollToTop>
    </Router>
  )
}

export default MyNav
