import { React, useState, useEffect, useRef } from 'react'
import Logo from '../../../assets/images/LogoGreen.png'
import { connect } from 'react-redux'
import { ReactComponent as Search } from '../../../assets/icons/Search.svg'
import { ReactComponent as Menu } from '../../../assets/icons/Menu.svg'
import { ReactComponent as Times } from '../../../assets/icons/Times.svg'
import { ReactComponent as ChevronDown } from '../../../assets/icons/ChevronDown2.svg'
import { ReactComponent as CircledPlay } from '../../../assets/icons/CircledPlay.svg'
import { NavLink, Link } from 'react-router-dom'
import Button from '../button/button.component'
import { fetchSearchArticles } from '../../../redux/actions/articlesActions'
import { clearSuccess } from '../../../redux/actions/successActions'
import { isVideo, slugify } from '../../../assets/js/globalFunctions'
import './css/style.css'

let timeout = null

const Toolbar = props => {
  const { categories, searchArticles, success } = props
  const [showMenu, setShowMenu] = useState(false)
  const [showCategories, setShowCategories] = useState(false)
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)

  const mounted = useRef(false)

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    } else {
      if (success.id) {
        if (success.id === 'FETCH_SEARCH_ARTICLES_SUCCESS') {
          setSearching(false)
        }
        props.clearSuccess()
      }
    }
  })

  useEffect(() => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (search.length < 4) return
      props.fetchSearchArticles(search)
      setSearching(true)
    }, 1500)
  }, [search])

  const showMenu_ = () => {
    setShowMenu(true)
  }
  const hideMenu = () => {
    setShowMenu(false)
  }
  const toggleShowCategories = () => {
    setShowCategories(!showCategories)
  }
  return (
    <div id='toolbar'>
      <div
        className='d-flex align-items-center page-padding py-4'
        style={{
          width: '100%',
          borderBottom: '1px solid rgba(0,0,0,.1)'
        }}
      >
        <Link to='/'>
          <img src={Logo} />
        </Link>
        <div className='ml-auto relative d-flex align-items-center search'>
          <Search className='relative' style={{ zIndex: 3 }} />
          <input
            style={{
              padding: '10px 40px 10px 10px',
              borderRadius: 8,
              border: '1px solid lightgrey',
              position: 'absolute',
              right: '-15px',
              width: 300,
              maxWidth: '70vw'
            }}
            value={search}
            onInput={e => {
              setSearch(e.target.value)
            }}
          />
          <div className='search-results'>
            {!searching &&
              searchArticles.map((art, index) => (
                <Link
                  to={`/articles/${slugify(art.title)}/?id=${art._id}`}
                  key={art._id}
                  className={`d-flex ${
                    index > 0 ? 'mt-3' : ''
                  } align-items-center`}
                >
                  {!isVideo(art.mediaUrl) && (
                    <img
                      src={art.mediaUrl}
                      style={{
                        maxWidth: 40,
                        maxHeight: 40,
                        minWidth: 40,
                        minHeight: 40,
                        objectFit: 'cover',
                        borderRadius: 5
                      }}
                    />
                  )}
                  {isVideo(art.mediaUrl) && (
                    <div className='relative'>
                      <video
                        style={{
                          maxWidth: '2.8rem',
                          maxHeight: '2.8rem',
                          minWidth: '2.8rem',
                          minHeight: '2.8rem',
                          objectFit: 'cover',
                          borderRadius: 5
                        }}
                      >
                        <source src={art.mediaUrl + '#t=0.1'}></source>
                      </video>
                      <div
                        style={{
                          position: 'absolute',
                          zIndex: '2',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%'
                        }}
                        className='d-flex align-items-center justify-content-center'
                      >
                        <CircledPlay
                          style={{
                            maxWidth: '1rem',
                            minWidth: '1rem',
                            fill: 'rgba(38, 170, 118, .7)'
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className='ml-2' style={{ fontSize: '.9em' }}>
                    {art.title}
                  </div>
                </Link>
              ))}
            {searchArticles.length === 0 && !searching && (
              <div className='center'>{`${
                search.length > 3
                  ? 'No articles match this search'
                  : 'Enter minimum of four letters'
              }`}</div>
            )}
            {searching && (
              <div className='center'>
                <div className='loader-medium'></div>
              </div>
            )}
          </div>
        </div>
        <div className='d-none d-md-block'>
          <a
            href='https://myafrilearn.com/about'
            target='blank_'
            className='ml-4'
          >
            About us
          </a>
          <NavLink to='/about' className='ml-4'>
            Classes
          </NavLink>
          <a
            href='https://myafrilearn.com/login'
            target='blank_'
            className='ml-4'
          >
            <Button outlined>Log in</Button>
          </a>
          <a
            href='https://myafrilearn.com/register'
            target='blank_'
            className='ml-4'
          >
            <Button depressed>Join for free</Button>
          </a>
        </div>
        <Menu
          className='ml-4 d-md-none pointer'
          style={{ maxWidth: 25, minWidth: 25 }}
          onClick={showMenu_}
        />
      </div>
      <div
        className='d-none d-md-flex justify-content-center page-padding py-4'
        style={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.1)' }}
      >
        <NavLink exact to='/' className='c-nav-link'>
          All
        </NavLink>
        {categories.map((cat, index) => (
          <NavLink
            exact
            to={`/categories/${slugify(cat.name)}?id=${cat._id}`}
            className='ml-md-4 ml-lg-5 c-nav-link'
            key={cat._id}
          >
            {cat.name}
          </NavLink>
        ))}
      </div>
      {showCategories && (
        <div
          className='mini-categories page-padding py-3 d-md-none'
          style={{ width: '100%', borderBottom: '1px solid rgba(0,0,0,.1)' }}
        >
          <NavLink exact to='/' className='c-nav-link'>
            All
          </NavLink>
          {categories.map((cat, index) => (
            <NavLink
              exact
              to={`/categories/${slugify(cat.name)}?id=${cat._id}`}
              className='c-nav-link'
              key={cat._id}
            >
              {cat.name}
            </NavLink>
          ))}
        </div>
      )}
      <div
        className='w-100 center py-1 px-2 pointer d-md-none'
        style={{ borderBottom: '1px solid rgba(0,0,0,.1)' }}
        onClick={toggleShowCategories}
      >
        <ChevronDown
          style={{
            maxWidth: '1.3rem',
            minWidth: '1.3rem',
            transform: showCategories ? 'rotate(180deg)' : '',
            fill: 'var(--primary-color)'
          }}
        />
      </div>

      {showMenu && <div className='mini-menu'></div>}
      <div className={`side-menu ${showMenu ? 'show' : ''}`}>
        <Times
          style={{
            position: 'absolute',
            right: 10,
            top: 10,
            width: 30,
            fill: 'var(--primary-color)'
          }}
          className='pointer'
          onClick={hideMenu}
        />
        <a
          href='https://myafrilearn.com/about'
          target='blank_'
          className='block nav'
          onClick={hideMenu}
        >
          About us
        </a>
        <NavLink exact to='/classes' className='block nav' onClick={hideMenu}>
          Classes
        </NavLink>
        <div className='mt-4 d-flex'>
          <a href='https://myafrilearn.com/login' target='blank_'>
            <Button outlined style={{ fontSize: '.9rem' }} onClick={hideMenu}>
              Log in
            </Button>
          </a>
          <a href='https://myafrilearn.com/register' target='blank_'>
            <Button
              depressed
              style={{ fontSize: '.9rem' }}
              className='ml-3'
              onClick={hideMenu}
            >
              Join
            </Button>
          </a>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  categories: state.categories.categories,
  searchArticles: state.articles.searchArticles,
  error: state.error,
  success: state.success
})
export default connect(mapStateToProps, { fetchSearchArticles, clearSuccess })(
  Toolbar
)
