import React, { useState, useEffect } from 'react'

import { ReactComponent as Ellipse } from '../../../assets/icons/HorizontalEllipse.svg'
// import SelectField from "../../includes/select-field/select.component";

import './css/style.css'

const Pagination = Display => {
  const P = props => {
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(props.itemsPerPage || 10)
    const [displayItems, setDisplayItems] = useState(props.items)

    let noOfPages = Math.ceil(props.count / itemsPerPage)
    const offset = itemsPerPage * (currentPage - 1)

    useEffect(() => {
      if (props.skip === 0) setCurrentPage(1)
      setDisplayItems(props.items)
    }, [props.items])

    useEffect(() => {
      let arr = props.items.slice(offset, offset + itemsPerPage)
      setCurrentPage(1)
      setDisplayItems(arr)
    }, [itemsPerPage])

    useEffect(() => {
      let arr = props.items.slice(offset, offset + itemsPerPage)
      setDisplayItems(arr)
    }, [currentPage])

    const buttons = () => {
      if (noOfPages < 8) {
        return Array(noOfPages)
          .fill('')
          .map((_, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  setCurrentPage(index + 1)
                  props.onPageChange(index + 1)
                }}
                className={`page-btn general-shadow ${
                  index + 1 === currentPage ? 'active' : ''
                }`}
              >
                {index + 1}
              </button>
            )
          })
      } else {
        const getMiddleButtons = () => {
          let btns = []
          let controller
          if (currentPage < 4) controller = 3
          else if (currentPage > noOfPages - 2) controller = noOfPages - 2
          else controller = currentPage
          for (let i = controller - 1; i <= controller + 1; i++) {
            btns.push(
              <button
                onClick={() => {
                  setCurrentPage(i)
                  props.onPageChange(i)
                }}
                className={`page-btn general-shadow ${
                  currentPage === i ? 'active' : ''
                }`}
                key={i}
              >
                {i}
              </button>
            )
          }
          return btns
        }
        return (
          <div>
            <button
              onClick={() => {
                setCurrentPage(1)
                props.onPageChange(1, itemsPerPage)
              }}
              className={`page-btn general-shadow ${
                currentPage === 1 ? 'active' : ''
              }`}
            >
              1
            </button>
            {currentPage > 3 && (
              <button
                style={{
                  padding: 0,
                  border: 'none',
                  backgroundColor: 'inherit'
                }}
              >
                <Ellipse />
              </button>
            )}
            {getMiddleButtons()}
            {currentPage < noOfPages - 2 && (
              <button
                style={{
                  padding: 0,
                  border: 'none',
                  backgroundColor: 'inherit'
                }}
              >
                <Ellipse />
              </button>
            )}
            <button
              onClick={() => {
                setCurrentPage(noOfPages)
                props.onPageChange(noOfPages)
              }}
              className={`page-btn general-shadow ${
                currentPage === noOfPages ? 'active' : ''
              }`}
            >
              {noOfPages}
            </button>
          </div>
        )
      }
    }

    return (
      <div id='pagination'>
        <Display
          {...props}
          items={displayItems}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
        ></Display>
        <div className='d-flex justify-content-center mt-3 mb-3'>
          {buttons()}
        </div>
      </div>
    )
  }
  return P
}

export default Pagination
